var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"d-flex justify-center"},[_c('div',{},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"Auth-button",class:_vm.tab == 'one'
            ? 'd-bg-primary d-text-light'
            : 'd-bg-light_gray d-text-dark'},[_c('v-btn',{staticClass:"d-border-radius elevation-0 subtitle2",class:_vm.tab == 'one'
              ? 'd-bg-primary d-text-light'
              : 'd-bg-light_gray d-text-dark',staticStyle:{"border-radius":"10px"},on:{"click":function($event){return _vm.change('one')}}},[_vm._v(_vm._s(_vm.$t("login")))])],1),_c('div',{staticClass:"Auth-button",class:_vm.tab == 'two'
            ? 'd-bg-primary d-text-light'
            : 'd-bg-light_gray d-text-dark',style:(_vm.$i18n.locale == 'ar' ? 'margin-right: 150px' : 'margin-left: 150px')},[_c('v-btn',{staticClass:"d-border-radius elevation-0 subtitle2",class:_vm.tab == 'two'
              ? 'd-bg-primary d-text-light'
              : 'd-bg-light_gray d-text-primary',staticStyle:{"border-radius":"10px"},on:{"click":function($event){return _vm.change('two')}}},[_vm._v(_vm._s(_vm.$t('Signin')))])],1)]),_c('v-col',{staticClass:"d-p-relative",attrs:{"cols":"12 "}},[(_vm.tab == 'one')?_c('v-card',{staticClass:"Auth-box d-bg-light_gray d-border-radius"},[_c('LoginForm')],1):_vm._e(),(_vm.tab == 'two')?_c('v-card',{staticClass:"Auth-box d-bg-light_gray d-border-radius"},[_c('RegisterForm')],1):_vm._e(),_c('div',{staticClass:"mark"})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }